import { useRouter } from "next/router";
import { Box, Typography } from "@mui/material";
import ArrowIcon from "../Icons/ArrowIcon";
import { useScreenSize } from "src/hooks/useScreenSize";
import { useContext } from "react";
import { LocalizationContext } from "../../lib/context";

export function HomeTitleSection({
  link,
  title,
  subtitle,
  itemQuantity = 0,
  subtitle2,
  dark,
  onShowAll,
}: {
  link?: string;
  title: string;
  subtitle?: string;
  subtitle2?: string;
  itemQuantity?: number;
  dark?: boolean;
  onShowAll?: () => void;
}) {
  const { uiGeneral } = useContext(LocalizationContext).localeStrings;
  const router = useRouter();
  const smAndDown = useScreenSize("sm", "down");

  return (
    <Box
      className="justify-content-space-between"
      sx={{
        alignItems: { xs: "center", sm: "flex-end" },
        display: { xs: "block", sm: "flex" },
      }}
    >
      <div>
        <Box className="flex justify-content-space-between align-items-center">
          <Typography
            variant="h2"
            onClick={() => link && router.push(link)}
            className="link-hover pointer headline-3 text-text"
            sx={{
              fontSize: { xs: "28px", md: "32px" },
              fontWeight: { xs: "500", md: "700" },
            }}
          >
            {title}
          </Typography>

          <Box
            sx={{
              display: { xs: "block", sm: "none", height: "16px" },
            }}
            className="pointer"
            onClick={() => {
              link && router.push(link);
              onShowAll && onShowAll();
            }}
          >
            <ArrowIcon type="right" color="black" width={9} height={16} />
          </Box>
        </Box>

        {subtitle && (
          <h3 className="subtitle-6 text-text" style={{ marginTop: "8px" }}>
            {subtitle}
          </h3>
        )}

        {subtitle2 && (
          <h3 className="subtitle-6 text-text" style={{ marginTop: "5px" }}>
            {subtitle2}
          </h3>
        )}
      </div>

      {!smAndDown && itemQuantity > 0 && (
        <Box
          onClick={() => {
            link && router.push(link);
            onShowAll && onShowAll();
          }}
          className="d-flex h-full pointer align-items-center gap-normal"
        >
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "26px",
              color: dark ? "#4D4E4E" : "#2496CC",
            }}
          >
            {uiGeneral.all} ({itemQuantity})
          </Typography>
          <ArrowIcon type="right" color={dark ? "#4D4E4E" : "#2496CC"} />
        </Box>
      )}
    </Box>
  );
}
