import { Box, Button, CardActionArea, Typography } from "@mui/material";
import theme from "../styles/theme";
import { useRouter } from "next/router";
import React, { useContext, useState } from "react";
import OfferPopup from "./OfferPopup";
import { UpgradePopup } from "./CommonComponents/UpgradePopup";
import { getJobState } from "../lib/global2";
import { useUserData } from "../hooks/useUserData";
import { LocalizationContext } from "../lib/context";

export function BoardBtn({
  job,
  dronerTenders,
}: {
  job: any;
  dronerTenders: any;
}) {
  const { localeStrings } = useContext(LocalizationContext);
  const localizedStrings = localeStrings.jobStates;
  const router = useRouter();
  const jobState = getJobState(job, dronerTenders, localizedStrings);

  return (
    <Button
      disabled={jobState.context.boardButtonDisabled ?? true}
      color={jobState.context.boardButtonColor ?? "warning"}
      variant="contained"
      sx={{
        [theme.breakpoints.down("md")]: {
          m: 0,
        },
        fontWeight: 600,
        fontSize: "1.2rem",
        textTransform: "capitalize",
        borderRadius: "unset",
        padding: "14px 0",
        backgroundColor: "primary.light",
      }}
      onClick={() => router.push(`/jobs/${job.id}`)}
    >
      {jobState.context.boardButtonText ?? "no data"}
    </Button>
  );
}

export function OneJobBtn({
  job,
  dronerTenders,
  className = "",
}: {
  job: any;
  dronerTenders: any;
  className?: string;
}) {
  const { localeStrings } = useContext(LocalizationContext);
  const localizedStrings = localeStrings.jobStates;
  const { actDroner } = useUserData();
  const jobState = getJobState(job, dronerTenders, localizedStrings);
  const [upgradePopupOpen, setUpgradePopupOpen] = useState(false);
  const [sendOfferOpen, setSendOfferOpen] = useState(false);

  function handleSubmit() {
    if (!actDroner || actDroner.subscriptionLevel !== "enterprise") {
      return setUpgradePopupOpen(true);
    } else if (jobState.context.canMakOffer) {
      setSendOfferOpen(true);
    }
  }

  return (
    <div>
      <Box
        className={` px-large ${className}`}
        sx={{
          position: "relative",
          width: "100%",
        }}
      >
        <CardActionArea
          className="card-shadow rounded-all-large"
          disabled={jobState.context.oneJobButtonDisabled ?? true}
          sx={{
            p: 2,
            mb: 2,
            color: "paper",
            borderRadius: 100,
            textAlign: "center",
            textTransform: "uppercase",
            fontWeight: 600,
            padding: "20px 0",

            backgroundColor: jobState.context.oneJobButtonDisabled
              ? "gray"
              : "success.light",
          }}
          onClick={() => handleSubmit()}
        >
          {jobState.context.oneJobButtonText}
          {!actDroner ||
            (actDroner.subscriptionLevel !== "enterprise" && (
              <Typography
                color="primary"
                sx={{ display: "inline-block" }}
                fontWeight={600}
              >
                *enterprise only
              </Typography>
            ))}
        </CardActionArea>
      </Box>
      <OfferPopup
        open={sendOfferOpen}
        setOpen={setSendOfferOpen}
        tender={job}
        offerMetadata={{
          tenderName: job.title,
          tender: job.id,
          currency: job.offerCurrency,
          uid: actDroner?.uid,
          email: actDroner?.email,
          username: actDroner?.username,
        }}
      />
      <UpgradePopup
        setOpen={setUpgradePopupOpen}
        open={upgradePopupOpen}
        text="Please Upgrade your subscription, to upload jobs"
      />
    </div>
  );
}
