import React from "react";

const ScrollXLayout = (props: {
  children: React.ReactNode;
  className?: string;
  containerRef?: any;
}) => {
  // @ts-ignore
  return (
    <div
      className={`scrollX__items ${props.className ? props.className : ""}`}
      ref={props.containerRef}
    >
      {props.children}
    </div>
  );
};

export default ScrollXLayout;
