import { Box, CardActionArea, Modal, Typography } from "@mui/material";
import { smallModalCard } from "../../styles/globalStyles";
import Image from "next/legacy/image";
import { useContext, useState } from "react";
import { LocalizationContext } from "../../lib/context";
import { useRouter } from "next/router";

export function UpgradePopup({ open, setOpen, text }) {
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const { localeStrings } = useContext(LocalizationContext);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <CardActionArea
        sx={{ ...smallModalCard }}
        onClick={() => {
          setLoading(true);
          router.push("/pricing").then((r) => setLoading(false));
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            gap: 2,
            p: 2,
            minWidth: 200,
          }}
        >
          <Box
            sx={{
              height: 130,
              width: "100%",
              position: "relative",
              overflow: "hidden",
            }}
            borderRadius={2}
          >
            <Image
              alt="rocket"
              src="/assets/drone.svg"
              sizes="(min-width: 100vw) 100vw,
                  (min-width: 100vw) 100vw,
                 100vw"
              layout="fill"
              objectFit="contain"
              quality={100}
            />
          </Box>
          <Typography variant="h2">
            {localeStrings.uiGeneral.modalTitle}
          </Typography>
          <Typography>{text}</Typography>
          <Box
            sx={{
              backgroundColor: "#558E31",
              py: 1,
              my: 1,
              px: 2,
              borderRadius: 20,
            }}
          >
            <Typography sx={{ color: "paper" }}>
              {localeStrings.uiGeneral.modalButton}
            </Typography>
          </Box>
        </Box>
      </CardActionArea>
    </Modal>
  );
}
