import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  Modal,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { smallModalCard } from "../styles/globalStyles";
import { useForm } from "react-hook-form";
import { dcContent } from "../../services/content";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../api_config/firebase-configuration";
import Image from "next/legacy/image";

export default function OfferPopup({ open, setOpen, offerMetadata, tender }) {
  const [loading, setLoading] = useState(false);
  const [accepted1, setAccepted1] = useState(false);
  const [acceptError1, setAcceptError1] = useState(null);
  const [accepted2, setAccepted2] = useState(false);
  const [acceptError2, setAcceptError2] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { offer: "", currency: "" } });
  const sendOfferFn = httpsCallable(functions, "tenders-sendOffer");
  const sendOffer = async (data) => {
    if (!accepted1)
      setAcceptError1("Please accept, that you are accountable for this work");
    if (!accepted2) setAcceptError2("please accept the terms of conditions");
    if (!accepted1 || !accepted2) return;
    setLoading(true);
    try {
      const res = await sendOfferFn({
        ...offerMetadata,
        offer: parseInt(data.offer),
        offerDescription: data.offerDescription,
        currency: offerMetadata.currency,
      });
      location.reload();
    } catch (e) {
      setLoading(false);
      alert(e.toString());
    }
  };
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box sx={{ ...smallModalCard }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            px: 2,
            alignItems: "center",
          }}
        >
          <Typography variant="h2" textAlign="center">
            Send us an offer for this job
          </Typography>
          <Typography
            variant="subtitle1"
            textAlign="center"
            sx={{ color: "success.main" }}
          >
            {tender.title}
          </Typography>
          <FormControl
            sx={{ gap: 2, width: "100%" }}
            component="form"
            onSubmit={handleSubmit(sendOffer)}
          >
            {dcContent.forms.tenderOfferForm.map((det, i) => {
              switch (det.formType) {
                case "textField":
                  return (
                    <Box
                      key={i}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel htmlFor={det.id}>{det.title}</InputLabel>

                        <OutlinedInput
                          sx={{ flexGrow: 1 }}
                          multiline={det.multiline}
                          type={det.type}
                          id={det.id}
                          label={det.title}
                          error={!!errors[det.id]}
                          {...register(det.id, {
                            required: {
                              value: det.required,
                              message: det.requiredMessage,
                            },
                            min: {
                              value: det.minValue,
                              message: det.rangeErrorMessage,
                            },
                            max: {
                              value: det.maxValue,
                              message: det.rangeErrorMessage,
                            },
                            minLength: {
                              value: det.minLength,
                              message: det.minLengthMessage,
                            },
                            maxLength: {
                              value: det.maxLength,
                              message: det.maxLengthMessage,
                            },
                            pattern: {
                              value: det.pattern,
                              message: det.patternMessage,
                            },
                          })}
                        />
                        <FormHelperText error>
                          {errors[det.id]?.message}
                        </FormHelperText>
                      </FormControl>

                      {i === 0 && (
                        <Typography className="pl-small">
                          {offerMetadata.currency}
                        </Typography>
                      )}
                    </Box>
                  );
              }
            })}

            <div>
              <Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    value="remember"
                    color="primary"
                    defaultChecked={false}
                    onChange={(e) => setAccepted1(e.target.checked)}
                  />
                  <Typography style={{ textTransform: "uppercase" }}>
                    I am accountable for work.
                  </Typography>
                </Box>
                {!accepted1 && typeof acceptError1 === "string" && (
                  <Typography color="error">{acceptError1}</Typography>
                )}
              </Box>
              <Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    value="remember"
                    color="primary"
                    defaultChecked={false}
                    onChange={(e) => setAccepted2(e.target.checked)}
                  />
                  <Typography style={{ textTransform: "uppercase" }}>
                    I accept the{" "}
                    <a
                      href="/assets/docs/General_Terms_And_Conditions_Official.pdf"
                      target="blank"
                    >
                      terms and conditions
                    </a>
                  </Typography>
                </Box>
                {!accepted2 && typeof acceptError2 === "string" && (
                  <Typography color="error">{acceptError2}</Typography>
                )}
              </Box>
            </div>
            <Box
              sx={{ display: "flex", justifyContent: "center", gap: 2 }}
              mt={2}
            >
              <LoadingButton
                variant="contained"
                color="primary"
                loading={loading}
                type="submit"
              >
                {dcContent.buttonTexts.sendBtn}
              </LoadingButton>
            </Box>
          </FormControl>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: 200,
            backgroundSize: "contain",
            my: 1,
            position: "relative",
          }}
        >
          <Image
            alt={tender.title}
            src="/assets/offer_popup_img.webp"
            sizes="(min-width: 100vw) 100vw,
                  (min-width: 100vw) 100vw,
                 100vw"
            layout="fill"
            objectFit="contain"
            quality={50}
          />
        </Box>
      </Box>
    </Modal>
  );
}
