import { useRouter } from "next/router";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { BoardBtn } from "./JobBtn";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import ArchitectureOutlinedIcon from "@mui/icons-material/ArchitectureOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";

export function JobCard({ job, dronerTenders, offerDeadlineText }) {
  let image;
  const iconStyle = { fontSize: "40px", color: "#828282" };

  switch (job.projectType) {
    case "agricultural":
      image = <AgricultureIcon sx={iconStyle} />;
      break;
    case "survey and mapping":
      image = <ArchitectureOutlinedIcon sx={iconStyle} />;
      break;
    case "creative":
      image = <ColorLensOutlinedIcon sx={iconStyle} />;
      break;
    case "education":
      image = <SchoolOutlinedIcon sx={iconStyle} />;
      break;
    case "service":
      image = <HandshakeOutlinedIcon sx={iconStyle} />;
      break;
    case "other":
      image = <PanoramaFishEyeIcon sx={iconStyle} />;
      break;
  }

  const router = useRouter();
  return (
    <div
      onClick={() => router.push(`/jobs/${job.id}`)}
      className="pointer flex relative col bg-surface rounded-all-normal justify-content-space-between card-hover border-text-lighten2-tiny"
      style={{ overflow: "hidden" }}
    >
      <div className="p-large h-full flex col justify-content-space-between">
        <div className="flex">
          <div className="flex">
            <div className="flex relative mr-small">{image}</div>
          </div>
          <div className="flex col justify-content-space-between pl-normal">
            <div
              onClick={() => router.push(`/jobs/${job?.id}`)}
              className="pointer flex text-text subtitle-5 link-hover"
            >
              {job?.title.length > 30
                ? job?.title.substring(0, 30 - 3) + "..."
                : job?.title}
            </div>
            <div className=" flex subtitle-6 capitalize text-text-lighten1">
              {job.projectType ?? "no type"}
            </div>
          </div>
        </div>
        <div className="flex align-items-center">
          <LocationOnOutlinedIcon
            sx={{ color: "primary.light", fontSize: "25px" }}
          />
          <p className="body-3 px-small text-text">
            {job.place ?? "City"}, {job.country ?? "Country"}
          </p>
        </div>
      </div>
      <div className="flex col align-items-center gap-small rounded-all-normal p-small mb-large">
        <p className="subtitle-6 text-text-lighten2">{offerDeadlineText}</p>
        <p suppressHydrationWarning className="subtitle-3 text-text">
          {new Date(job?.offerDeadline).toLocaleDateString() ?? "Deadline"}
        </p>
      </div>
      {dronerTenders && (
        <div className="flex col w-full">
          <BoardBtn job={job} dronerTenders={dronerTenders} />
        </div>
      )}
    </div>
  );
}
